import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import React from "react";
import ReactDOM from "react-dom";
import VideoInput from "../components/VideoInput";

export default class extends Controller {
  static targets = ["video"];

  connect() {
    // Render react component
    console.log(this.videoTarget);

    const element = document.createElement("div");
    this.videoTarget.appendChild(element);

    ReactDOM.render(<VideoInput onResult={this.qrCodeFound} />, element);

    this.videoElement = element;
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.videoElement);
  }

  qrCodeFound(code) {
    console.log("QR code found", code);

    const url = new URL(window.location.href);
    url.searchParams.delete("code");
    url.searchParams.append("code", code);
    Turbo.visit(url.toString());
  }
}
