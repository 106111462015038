import { Controller } from "stimulus";
import "payhere-embed-sdk/dist/embed.css";
import Payhere from "payhere-embed-sdk/dist/embed";

export default class extends Controller {
  static targets = ["plan", "paymentToken", "billingAddress", "postcode"];
  static values = {
    email: String,
    promo: String,
    payhereMonthly: String,
    payhereAnnual: String,
    billingAddress: String,
    postcode: String,
  };

  connect() {
    this.plan = "year";
  }

  changePlan(e) {
    e.preventDefault();

    this.plan = e.currentTarget.dataset.plan;

    console.log("this.plan", this.plan, this.emailValue, this.promoValue);

    this.planTargets.forEach((target) => target.classList.toggle("-active"));
  }

  openPayment() {
    Payhere.launch({
      embedURL:
        this.plan === "month"
          ? this.payhereMonthlyValue
          : this.payhereAnnualValue,
      customerEmail: this.emailValue,
      customFields: {
        customer_email: this.emailValue,
        billing_address: this.billingAddressValue,
        postcode: this.postcodeValue,
        referral_code: this.promoValue,
      },
      onSuccess: (data) => {
        // Save JWT in form
        console.log("onSuccess", data.token);
        this.paymentTokenTarget.value = data.token;
        this.billingAddressTarget.value = data.customFields.billing_address;
        this.postcodeTarget.value = data.customFields.postcode;
        this.success = true;
      },
      onFailure: (err) => {
        // show error
        console.error("onFailure", err);
      },
      onClose: () => {
        // If success submit form
        console.log("onClose");
        if (this.success) this.submit();
      },
    });
  }

  submit() {
    this.element.submit();
  }
}
