import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const Locationsmap = ({ locations, mapMarkerIcon, googleMapsApiKey }) => {
  const locationUnavailable = true;
  const coords = null;

  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={googleMapsApiKey}
      onError={(err) => console.error(err)}
      onLoad={() => {}}
    >
      <GoogleMap
        id="locations-map"
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
        }}
        onLoad={(map) => {
          const bounds = new window.google.maps.LatLngBounds();
          locations.forEach((loc) => {
            bounds.extend(loc.geo);
          });
          if (coords) {
            bounds.extend({ lat: coords.latitude, lng: coords.longitude });
          }
          map.fitBounds(bounds);
        }}
      >
        {locations.map((location) => (
          <Marker
            key={JSON.stringify(location)}
            position={location.geo}
            title={location.address}
            icon={mapMarkerIcon}
            zIndex={200}
          />
        ))}
        {!locationUnavailable && coords && (
          <Marker
            position={{ lat: coords.latitude, lng: coords.longitude }}
            title="Your location"
            icon={{
              scaledSize: { width: 25, height: 25 },
              url: mapUserIcon,
            }}
            zIndex={300}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default Locationsmap;
