import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom";
import LocationsMap from "../components/LocationsMap";

export default class extends Controller {
  static values = {
    locations: String,
    mapMarkerIcon: String,
    apiKey: String,
  };
  static targets = ["map"];

  connect() {
    // Render react component
    const element = document.createElement("div");
    this.mapTarget.appendChild(element);

    ReactDOM.render(
      <LocationsMap
        mapMarkerIcon={this.mapMarkerIconValue}
        locations={this.locations}
        googleMapsApiKey={this.apiKeyValue}
      />,
      element
    );

    this.mapElement = element;
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.mapElement);
  }

  get locations() {
    try {
      return JSON.parse(this.locationsValue);
    } catch (error) {
      console.error("Failed parsing location", error.message);
      return [];
    }
  }
}
