import { Controller } from "stimulus";

export default class extends Controller {
  dismiss(event) {
    event.preventDefault();
    event.stopPropagation();

    this.element.style.display = "none";
  }
}
