import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "button"];

  connect() {
    this.originalText = this.buttonTarget.innerText;
  }

  copy() {
    this.sourceTarget.select();
    document.execCommand("copy");
    this.buttonTarget.innerText = "Copied";
    setTimeout(() => {
      this.buttonTarget.innerText = this.originalText;
    }, 1000);
  }
}
