import React, { useState } from "react";
import QrReader from "react-qr-reader";

const VideoInput = ({ onResult }) => {
  const [scanning, setScanning] = useState(false);

  return (
    <>
      {scanning ? (
        <>
          <QrReader
            delay={300}
            onError={(err) => console.error(err)}
            onScan={(data) => {
              if (data) {
                console.log({ data });
                setScanning(false);
                onResult(encodeURIComponent(data));
              }
            }}
            style={{ width: "100%" }}
          />
          <div
            className="close white pa2 pointer"
            onClick={() => setScanning(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </>
      ) : (
        <button
          type="button"
          className="scan-btn dim"
          onClick={() => {
            setScanning(true);
          }}
        >
          Scan
        </button>
      )}
    </>
  );
};

export default VideoInput;
