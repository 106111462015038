import _firebase from "firebase/app";
import "firebase/auth";

const apiKey = document.querySelector(`meta[name="firebase:api-key"]`).content;

export const firebaseConfig = {
  apiKey,
  authDomain: "eni-plus.firebaseapp.com",
  projectId: "eni-plus",
};

export const firebase = _firebase.initializeApp(firebaseConfig);

export default firebase;
