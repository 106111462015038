import { Controller } from "stimulus";
import blocksToHtml from "@sanity/block-content-to-html";

// `h` is a way to build HTML known as hyperscript
// See https://github.com/hyperhype/hyperscript for more info
// const h = blocksToHtml.h;

// const serializers = {
//   types: {
//     code: (props) =>
//       h("pre", { className: props.node.language }, h("code", props.node.code)),
//   },
// };

export default class extends Controller {
  connect() {
    this.element.innerHTML = this.htmlContent;
  }

  disconnect() {
    //
  }

  get htmlContent() {
    const content = JSON.parse(this.data.get("content"));
    const cssClass = this.data.get("css-class");

    return blocksToHtml({
      className: cssClass,
      blocks: content,
      projectId: "2dcgdq3u",
      dataset: "production",
      // serializers: serializers,
    });
  }
}
