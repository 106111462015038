import qs from "qs";
import { Controller } from "stimulus";
import firebase from "../utils/firebase";
import { Turbo } from "@hotwired/turbo-rails";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["email", "password", "error", "submit"];

  connect() {
    this.buttonText = this.submitTarget.value;
  }

  login(e) {
    e.preventDefault();

    this.submitTarget.value = "Logging in";

    const { email, password } = this;

    Rails.ajax({
      url: "/session",
      type: "GET",
      data: qs.stringify({
        email,
      }),
      success: (data) => {
        this.element.submit();
      },
      error: () => {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(() => {
            firebase
              .auth()
              .currentUser.getIdToken(/* forceRefresh */ true)
              .then(async (token) => {
                Rails.ajax({
                  url: "/session/verify",
                  type: "POST",
                  data: qs.stringify({
                    password,
                    token,
                  }),
                  success: () => {
                    Turbo.visit("/");
                  },
                  error: () => {
                    console.error("TOKEN Verification failed");
                    this.showError("Your email/password is incorrect");
                  },
                });
              })
              .catch(({ message }) => {
                console.error("idToken failed!!", message);
                this.showError("Your email/password is incorrect");
              });
          })
          .catch(({ message }) => {
            console.error("Auth failed!!", message);
            this.showError("Your email/password is incorrect");
          });
      },
    });
  }

  showError(message) {
    this.errorTarget.textContent = message;
    this.errorTarget.classList.remove("dn");
    this.passwordTarget.value = "";
    this.submitTarget.removeAttribute("disabled");
    this.submitTarget.value = this.buttonText;
  }

  get email() {
    return this.emailTarget.value;
  }

  get password() {
    return this.passwordTarget.value;
  }
}
