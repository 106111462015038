import { Controller } from "stimulus";
import QRCodeToCanvas from "qrcode";

export default class extends Controller {
  connect() {
    const qrCode = this.qrCode;

    if (!qrCode) return;

    const canvasEl = document.createElement("canvas");

    QRCodeToCanvas.toCanvas(canvasEl, qrCode, (err) => {
      if (err) return console.error("Error generating QR code", err);

      console.log("QR gen success", qrCode);
      this.element.appendChild(canvasEl);
    });
  }

  get qrCode() {
    return this.data.get("code");
  }
}
