import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    hiddenClass: String,
  };
  static targets = ["filterable"];

  disconnect() {
    this.clearAllFilters();
  }

  perform(e) {
    const applied = e.target.value;

    if (applied !== "") {
      this.applyFilter(applied);
    } else {
      this.clearAllFilters();
    }
  }

  clearAllFilters() {
    return this.filterableTargets.forEach((target) => {
      target.classList.remove(this.hiddenClassValue);
    });
  }

  applyFilter(applied) {
    this.filterableTargets.forEach((target) => {
      const categories = target.dataset.categories.split(",");
      if (categories.includes(applied)) {
        target.classList.remove(this.hiddenClassValue);
      } else {
        target.classList.add(this.hiddenClassValue);
      }
    });
  }
}
